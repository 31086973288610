<!--
 * @Author: pengyu
 * @Date: 2020-07-14 15:40:47
 * @LastEditors: wuqi
 * @LastEditTime: 2021-08-02 09:57:51
 * @Description: 日期控件
-->
<template>
  <!-- 加密模式 非区间-->
  <el-input
    v-if="column.isHiddenValueState && column.dateRange===1"
    value="******"
    @click="showPicker"
  ></el-input>
  <!-- 加密模式 区间-->
  <div
    class="hide-value-range"
    :class="!column.editable ? 'disable' : ''"
    v-else-if="column.isHiddenValueState && column.dateRange===0"
    @click="showPicker"
  >
    <el-input type="text" value="******" :disabled="!column.editable"></el-input>
    <span :class="!column.editable ? 'disable' : ''">至</span>
    <el-input type="text" value="******" :disabled="!column.editable"></el-input>
  </div>
  <!-- 使用模式 -->
  <div v-else class="date-picker-wrap">
    <!-- 编辑模式 -->
    <div class="date-picker-edit" v-if="column.editable">
      <en-date-picker
        :id="column.field"
        v-model="column.showValue"
        :type="column.getDatePickerType()"
        :format="column.getDatePickerFormat()"
        :value-format="column.getDatePickerFormat()"
        :disabled="!column.editable"
        range-separator="至"
        :start-placeholder="column.instructions || '开始时间'"
        :end-placeholder="column.instructions || '结束时间'"
        :placeholder="column.showPlaceholder"
        :style="{...bindStyle,...inputStyle(column.field)}"
        class="date-picker"
        @change="handleValueChange"
        @focus="handleChange"
        ref="dPicker"
        :picker-options="pickerOptions"
      >
      <!-- prefix-icon="iconxianshihenji-office" -->
      </en-date-picker>
      <div class="static-date" v-if="column.columnValue && column.dateRange===0 && column.statisticalDate===0">
        {{column.getStaticDate}}
      </div>
      <RightAddition v-if="hasRightBtn"
                    :column="column"
                    :business-data="businessData">
      </RightAddition>
    </div>
    <!-- 查看模式 -->
    <div class="date-picker-view" v-else>
      <el-input
        :value="getFormatShowValue()"
        disabled
      ></el-input>
      <div class="static-date" v-if="column.columnValue && column.dateRange===0 && column.statisticalDate===0">
        {{column.getStaticDate}}
      </div>
    </div>

  </div>

</template>

<script>
import dayjs from "dayjs";
import { dayJsTimeFormat } from "@/tools/date.js";
import datePickerOptions from "@/mixins/datePickerOptions";
import MxColumnData from "../MxColumnData.js";
import { AssignmentOptions } from "../../../data/BusinessCommonHeader.js";
import MxRightAddition from "../components/rightAddition/MxRightAddition";
import RightAddition from "../components/rightAddition";

export default {
  name: "BMDateColumn",
  mixins: [MxColumnData, datePickerOptions, MxRightAddition],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 明细第几行
    rowIndex: {
      type: Number,
      default: 0
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    RightAddition
  },
  computed: {
    // 是否有右侧按钮组
    hasRightBtn() {
      return this.rightAdditionButtons?.length;
    }
  },
  data() {
    return {
      isUpdataStyle: false
    };
  },
  mounted() {
    if (this.column.dateRange === 0) {
      this.queryGlobalVarible();
    }
    console.log(this.column);
  },
  methods: {
    handleChange() {
      if (this.isUpdataStyle) return;
      setTimeout(() => {
        this.isUpdataStyle = true;
        // const document = window.document.getElementsByClassName("el-time-panel el-popper")[0];
        // // eslint-disable-next-line operator-assignment
        // document.style.left = `-${30}px`;
      }, 400);
    },
    /**
     * @description 手动值变更
     */
    handleValueChange() {
      console.log(this.column);
      this.column.updateValueWithOptions(this.column.showValue, AssignmentOptions.manual, this.rowIndex);
      // 模板上的关联属性 zjh
      this.businessData.templateHandle?.associatedDataHandle?.handle(this.column, this.rowIndex);
      // 抛出事件，在列表行内编辑时，提交保存
      this.$emit("trChange");
    },
    /**
     * @description: 去除加密设置
     */
    showPicker() {
      if (!this.column.editable) {
        return;
      }
      this.column.updateServerShowFlag(false);
    },
    /**
     * @description: 查看模式下的  显示值
     */
    getFormatShowValue() {
      const { timeFormat, showValue } = this.column;
      const format = dayJsTimeFormat[timeFormat];
      if (!showValue) return "";
      if (showValue instanceof Array) {
        const sd = showValue[0] ? dayjs(showValue[0]).format(format) : "";
        const ed = showValue[1] ? dayjs(showValue[1]).format(format) : "";
        return `${sd}至${ed}`;
      }
      return dayjs(showValue).format(dayJsTimeFormat[timeFormat]);
    }
  }
};
</script>

<style lang='scss' scoped>
.date-picker {
  width: 100%;
  & /deep/ .el-range-input{
    color:inherit;
    text-align: inherit;
    &:disabled{
      border: none;
      background: #f5f7fa;
      color: #636c78;
    }
  }
}
.en-input-range{
  width: 100%;
}
.hide-value-range{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e8ecf2;
  height: 33px;
  border-radius: 4px;
  box-sizing: border-box;
  /deep/ .el-input__inner{
    border: none;
    height: 31px;
    transform: translateY(-1px);
  }
  span.disable{
    background-color: #F5F7FA;
  }
  &:not(.disable):hover{
    border-color: #a2cdf7;
  }
}
.date-picker-edit,.date-picker-view{
  width: 100%;
  display: flex;
  align-items: center;
  &/deep/ .right-addition{
    display: flex;
  }
  .static-date{
    margin-left: 5px;
    color: #aeaeae;
    word-break: keep-all;
  }
}
.date-picker-wrap{
  & /deep/ .el-input__inner{
    border-color: $field-border-color;
  }
  & /deep/ .el-input__inner:hover{
    border-color: $field-border-color-hover;
  }
  & /deep/ .el-input__inner:focus{
    border-color: $field-border-color-focus;
  }
  & /deep/ .el-input__inner.is-active{
    border-color: $field-border-color-focus;
  }
  & /deep/ .riqixuankuang:after{
    content: "\ec2f"
  }
}
.date-picker-view{
  .el-input{
      width: 100%;
      & /deep/ .el-input__inner{
        border: none;
        background: #f5f7fa;
        text-decoration: inherit;
        // color: #636c78;
      }
    }
}
</style>
