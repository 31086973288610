<!--
 * @Author: xujun
 * @Date: 2020-08-31 09:01:57
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-30 14:27:22
 * @Description: 附件查看
-->
<template>
  <el-dialog
    :title="title || '附件'"
    :visible.sync="visible"
    destroy-on-close
    append-to-body
    width="900px">
    <div class="preview-box">
      <div class="content">
        <div class="image-box">
          <image-file v-for="(file, index) in allImageList"
                      type="image" size="small"
                      :key="file.id || file.uid"
                      direction="h"
                      @click.native="handleClickImage(index)"
                      :delete-method="handleDelete"
                      :hasDownload="true"
                      :data="file">
          </image-file>
        </div>
        <div class="file-box">
          <image-file v-for="(file, index) in allFileList"
                      :key="file.id || file.uid"
                      direction="v"
                      height="35"
                      @click.native="handleClickFile(index)"
                      :delete-method="handleDelete"
                      :hasDownload="true"
                      :data="file">
          </image-file>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { openOfficeOnline } from "@/tools/util";
import { getFileUrl } from "@/tools/getFileUrl";
import ImageFile from "../views/imageFile";
import Index from "../data/utils";

export default {
  name: "PreviewDialog",
  components: { ImageFile },
  props: {
    title: String,
    fileList: Array,
    mdConfig: Object,
    mode: {
      // 显示模式 (edit show)
      type: String,
      default: "show"
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    allImageList() {
      return Index.getImageList(this.fileList);
    },
    allFileList() {
      return Index.getFileList(this.fileList);
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    // 查看图片
    handleClickImage(index = 0) {
      const array = [];
      this.allImageList.forEach((element) => {
        const {
          code, type, name, url
        } = element;
        // 是不是移动端
        if (!url) {
          const item = { ...element, url: getFileUrl(code, type, name) };
          array.push(item);
        } else {
          array.push(element);
        }
      });
      this.$viewImage({
        datas: array,
        url: "url",
        thumUrl: "url",
        initialViewIndex: index
      });
    },
    handleClickFile(index = 0) {
      /* eslint-disable */
      return;
      const file = this.allFileList[index];
      const { extension, code } = file;
      const { isEdit, isAdd } = this.mdConfig;

      if (["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf"].includes(extension)) {
        let operType = 1;
        if (this.mode === "edit" && (isEdit === 0 || isAdd === 0)) {
          operType = 2;
        }
        // 这4种格式文件仅支持查看
        if (["doc", "ppt", "xls", "pdf"].includes(extension)) {
          operType = 1;
        }

        openOfficeOnline(operType, extension, code);
        return;
      }

      this.$message.warning("不支持用officeOnline打开的类型");
    },
    handleDelete(file) {
      this.$emit("handleDelete", file);
      // const index = Index.indexOfFile(this.fileList, file);
      // this.fileList.splice(index, 1);
    }
  }
};
</script>

<style scoped lang="scss">
  .preview-box {
    padding-bottom: 20px;

    .content {//滚动条好看, 加了一层
      overflow-y: auto;
      height: 550px;

      .image-box {
        /*display: flex;*/
        text-align: left;
      }
      .file-box {
        margin-top: 10px;
      }
    }

  }

</style>
