/*
 * @Description: 控件基类,处理通用字段和逻辑等
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2024-02-18 17:09:47
 */
import { enBooleanReverse, enEqual } from "@/tools/compare";
import MdPermission from "../../detailModel/permission";
import createColumn from "./extensions/createColumn";
import createMappingRule from "./extensions/mappingRule";
import inputLimitCheck from "./extensions/inputLimitCheck";
import {
 AssignmentOptions, BusinessDataOpenType, BusinessType, BusinessFieldType, SectionType
} from "../../BusinessCommonHeader";
import BusinessSection from "../../section/BusinessSection";
import BaseSection from "../../section/BaseSection";
import BaseNode from "./BaseNode";

// 请注意, _下划线开头的属性名为缓存属性,可以理解为私有变量,外部如果要使用,请找对应的get方法
// 创建固定类型的column,慎重使用
@createColumn
// 创建转换规则,估计还有的改
@createMappingRule
// 基本控件的校验,且或,金额,数值,时间,文本.
@inputLimitCheck

export default class BaseColumn extends BaseNode {
  // 权限信息 使用的话用get方法 这是缓存属性,可能会为空
  _permission;

  // 展示的值
  showValue;

  value = 1;

  columnValue;

  // 需要隐藏展示的值 有值代表需要隐藏
  showFlag;

  // 触发输入限制检查的一个watch
  triggerInputLimitWatch = 0;

  // 是否编辑过,需要把编辑过的字段传给后端
  hasBeenEdit = false;

  // 所属区域 主表,明细or子集 使用的话用get方法 这是缓存属性,可能会为空
  _belongSectionType;

  // 是否是区域里面的字段, 区域里面的布局不能flex1, 标记下
  isBelongToAreaColumn;

  // 曾经处理过的赋值方式 AssignmentOptions.Int << & |
  historyAssignment;

  // 最后的赋值方式 AssignmentOptions.Int << & |
  lastAssignment;

  // 基类记录赋值方式的修改
  updateValueWithOptions(_, opt, rowIndex) {
    /* eslint-disable-next-line */
    this.historyAssignment |= opt;
    this.lastAssignment = opt;
    // 除下列2种赋值外,其它都当做改动过 需要向后台提交改动过的字段
    const currentEdit = (opt !== AssignmentOptions.default && opt !== AssignmentOptions.server);
    const hasEdit = this.hasBeenEdit || currentEdit;
    this.hasBeenEdit = hasEdit;
    if (hasEdit) {
      window.fieldEditInfo = { ...window.fieldEditInfo, [this.field]: this.name };
    }
    // 改动字段本身可能导致配置了相关输入限制的字段重新触发
    this.triggerInputLimitIfNeeded();
    // 自变量字段赋值可能导致因变量字段变化(配置了计算公式)
    // this.filedWatch()
    // 查看详情不需要触发计算公式，编辑首次进入不需要触发计算公式
    if (!this.rootNode.notCalculate) {
      this.rootNode.templateHandle?.calculateExecute(this, this.subDetails, rowIndex);
      this.rootNode.templateHandle?.monitorCalculateExecute(this, this.subDetails, rowIndex);
      // 向上汇总
      this.rootNode.templateHandle?.summaryUpExecute(this);
    }
  }

  // 记录控件值的查看权限
  updateServerShowFlag(showFlag) {
    this.showFlag = showFlag;
  }

  // 暂时先这样写, 字段的值转换文本展示,后续有需要再改
  get textValue() {
    if (this.isHiddenValueState) {
      return this.showFlag;
    }
    return this.showValue || this.columnValue;
  }

  // 是否是隐藏值的状态
  get isHiddenValueState() {
    return this.showFlag && this.showFlag.length > 0;
  }

  // // 服务器数据 & 默认值数据赋值完毕之后,会回调此方法.(考虑到有些控件在此之后需要处理逻辑)
  // /* eslint-disable-next-line */
  // updatedValueFromServer() {
  //
  // }

  // 根据字段是否编辑过,来返回信息 明细,子集,地址需要特殊处理
  get fieldEditInfo() {
    if (this.hasBeenEdit) {
      return {
        [this.field]: this.name
      };
    }
    return {};
  }

  // 控件的提交数据
  /* eslint-disable-next-line */
  get paramsForSubmit() {
    return undefined;
  }

  // 是否能提交数据,校验
  /* eslint-disable-next-line */
  get checkCouldSubmit() {

  }

  // 所属区域类型 主表 明细 or 子集
  get belongSectionType() {
    if (this._belongSectionType) {
      return this._belongSectionType;
    }
    this._belongSectionType = this.searchParentNodeWithClassName(BusinessSection)?.sectionType;

    return this._belongSectionType;
  }

  // 主表区字段合集
  get mainAreaFields() {
    return this.rootNode.mainInfos;
  }

  // 主表区,明细区字段合集
  get allAreaFields() {
    return this.rootNode._allInfos;
  }

  // 找主表区对应field的字段
  findMainAreaField(field) {
    return this.findColumn(this.mainAreaFields, field, "field");
  }

  // 找明细区同级对应field的字段
  findDetailSameAreaField(field) {
    return this.findColumn(this.detailSameAreaFields, field, "field");
  }

  // 找子集区同级对应field的字段
  findSubsetSameAreaField(field) {
    return this.findColumn(this.subsetSameAreaFields, field, "field");
  }

  // 通过uuid找主表区对应field的字段
  findMainAreaFieldByUuid(uuid) {
    return this.findColumn(this.mainAreaFields, uuid, "uuid");
  }

  // 通过uuid找明细区同级对应field的字段
  findDetailSameAreaFieldByUuid(uuid) {
    return this.findColumn(this.detailSameAreaFields, uuid, "uuid");
  }

  // 通过uuid找子集区同级对应field的字段
  findSubsetSameAreaFieldByUuid(uuid) {
    return this.findColumn(this.subsetSameAreaFields, uuid, "uuid");
  }

  // 通过field找到指定字段 py
  findColumnByField(field) {
    // 查询是否为主表区字段
    let column = this.findMainAreaField(field);
    if (column) return column;
    // 查询是否为图文合成中字段
    const twArea = this.mainAreaFields?.filter((item) => item.fieldType === BusinessFieldType.textComposition);
    for (let i = 0; i < twArea?.length; i++) {
      column = twArea[i].sub?.filter((item) => item.field === field)[0];
      if (column) {
        break;
      }
    }
    if (column) return column;

    // 查询是否为明细区字段
    const mxArea = this.mainAreaFields?.filter((item) => item.fieldType === BusinessFieldType.detailParent);
    let zjArea = [];
    for (let i = 0; i < mxArea?.length; i++) {
      column = mxArea[i].sub?.filter((item) => item.field === field)[0];
      const zj = mxArea[i].sub?.filter((item) => item.fieldType === BusinessFieldType.subSet);
      zjArea = [...zjArea, ...zj];
      if (column) {
        break;
      }
    }
    if (column) return column;
    // 查询是否为子集区字段
    for (let i = 0; i < zjArea?.length; i++) {
      column = zjArea[i].sub?.filter((item) => item.field === field)[0];
      if (column) {
        break;
      }
    }

    // 查询是否为首付信息中字段
    const sfArea = this.mainAreaFields?.filter((item) => item.fieldType === BusinessFieldType.shouFu);
    for (let i = 0; i < sfArea?.length; i++) {
      column = sfArea[i].sub?.filter((item) => item.field === field)[0];
      if (column) {
        break;
      }
    }
    if (column) return column;
    return column || null;
  }

  // 明细区同行字段合集
  get detailSameAreaFields() {
    let parentNode = this;
    // 找到同行明细区
    while (parentNode && parentNode.sectionType !== SectionType.detail) {
      parentNode = parentNode.searchParentNodeWithClassName(BaseSection);
    }
    return parentNode?.fields;
  }

  // 子集区同行字段合集
  get subsetSameAreaFields() {
    let parentNode = this;
    // 找到同行子集区
    while (parentNode && parentNode.sectionType !== SectionType.subset) {
      parentNode = parentNode.searchParentNodeWithClassName(BaseSection);
    }
    return parentNode?.fields;
  }

  // 展示用placeholder
  get showPlaceholder() {
    if (this.hasSetCodeRule) {
      return "自动生成";
    }
    if (!this.editable) {
      // 不可以编辑直接返回空
      return "";
    }
    if (this.instructions?.length) {
      return this.instructions;
    }
    // 左侧配置没有显示的时候,显示字段名称
    if (this.showPrefix !== 0) {
      return this.name;
    }
    // 没有设置的时候默认的填写说明
    let dataDeclare = "";
    switch (this.fieldType) {
      case BusinessFieldType.inputCommon:
      case BusinessFieldType.inputNumber:
      case BusinessFieldType.inputAmount:
      case BusinessFieldType.multiText:
        dataDeclare = "请输入";
        break;

      case BusinessFieldType.select:
      case BusinessFieldType.date:
      case BusinessFieldType.multiSelect:
      case BusinessFieldType.cashObject:
        dataDeclare = "请选择";
        break;

      case BusinessFieldType.superior:
        dataDeclare = "请设置";
        break;
      case BusinessFieldType.attachment:
        dataDeclare = "请上传";
        break;
      case BusinessFieldType.associatedData:
        dataDeclare = this.name;
        break;
      default:
        break;
    }
    return dataDeclare;
  }

  // 字段是否展示的最终判断结果 get方法会多次调用,以后再优化
  get displayable() {
    // console.log('displayable',this)
    // 几个不校验展示权限的字段(自定义)
    if ([BusinessFieldType.notifier,
      BusinessFieldType.flow,
      BusinessFieldType.noticeReceipt,
      BusinessFieldType.log,
      BusinessFieldType.seeFlow,
      BusinessFieldType.comment,
      BusinessFieldType.approvalComment,
      BusinessFieldType.approvalOpinion,
      BusinessFieldType.area].includes(this.fieldType)) {
      return true;
    }
    // 设置了隐藏的控件，不显示
    /* eslint-disable */
    if (!enBooleanReverse(this.actived)) {
      return false;
    }

    //子项在查看状态下 无数据时不展示
    if (this.fieldType === BusinessFieldType.subItem && this.rootNode.openType.toString() === BusinessDataOpenType.view && !this.columnValue.length) {
      return false;
    }

    // // /配置了子项显示权限的，进入子项只显示配置显示的,级别最高
    // if (this.isConfigSubitemShowField && !this.showSubitemField) {
    //   return false;
    // }
    // // /配置了单选数据选项显示的，配置没生效的隐藏，级别在子项之后
    if (this.isConfigOptionShowField && !this.showOptionField) {
      return false;
    }
    // // /如果选项卡不是当前显示的选项，隐藏，级别在权限之前
    // if (this.isBelongsToTab && !this.showTabControllField) {
    //   return false;
    // }
    // // /正常显示权限,解析权限,权限能缓存的就缓存下
    // return this.isFieldShow;

    //当前为选项卡属性字段 先隐藏暂不做处理
    if (this.isTabAttrField === 0 && this.isTabField === 1) {
      return false;
    }
    // 没有权限list 默认可以展示 兼容基础数据
    if (!this.rootNode.powerList?.length) {
      return true;
    }
    // 页签的显示判断 有显示权限并且 内在的showColumnsFlg 为true才显示
    if (this.fieldType === BusinessFieldType.multiTab && this.permission.canDisplay) {
      return this.showColumnsFlg;
    }
    return this.permission.canDisplay;
  }

  // 是否必填 get方法会多次调用,以后再优化
  get required() {
    // if (!this.displayable) {  4.9.6需求要求不展示也要判断必填
    //   // 不展示不判断必填
    //   return false;
    // }
    // 设置了编码规则的默认不必填
    if (this.hasSetCodeRule) {
      return false;
    }
    if (this.permission.isFlowPermission) {
      // 拥有权限的必填的话就使用权限的必填
      return this.permission.isRequire;
    }
    //单选字段 选项控制 为必填
    if (this.isConfigOptionRequireField && this.requireOptionField) {
      return true;
    }
    // 明细区 子集等写死非必填, 它会影响下面字段的必填展示(form-item嵌套) 这里需要评估下影响面, 如果有bug,则需要把判断放到界面上
    if (this.fieldType === BusinessFieldType.detailParent) {
      return false;
    }
    // 否则使用自身的权限
    return enEqual(this.requrein, 0);
  }

  // 字段是否能编辑的最终判断结果 get方法会多次调用,以后再优化
  get editable() {
    // 明细选择添加类 渲染非本模板数据 基础数据字段不完整 给与特殊处理
    if (this.otherTemplate) return true;

    // 设置了编码规则,不能编辑
    if (this.hasSetCodeRule) {
      return false;
    }


    if (this.rootNode.businessType === BusinessType.pureBasic) {
      return this.rootNode.pageEdit;
    }

    // 没有权限list 默认可以编辑 也就是纯基础数据有这个逻辑 其实已经走了上面那个逻辑, 这个判断应该是无用的
    if (!this.rootNode.powerList?.length && this.rootNode.pageEdit) {
      return true;
    }


    return this.rootNode.pageEdit && this.permission.canEdit;

  }
  set editable(val) {
    this.otherTemplate = val;
  }
  // 字段的权限配置信息
  get permission() {
    if (this._permission) {
      return this._permission;
    }
    // 搜索字段权限
    this._permission = MdPermission.searchColumn(this.field, this.rootNode.powerList) || {};
    return this._permission;
  }

  /**
   * @description: 查找指定区域
   * @param {String} type:模板配置 主表区 明细 子集区
   * @return: 区域集合
   */
  findAreaFields(type = "mainTopArea") {
    // 区域表
    const areaObj = {
      "mainTopArea": this.mainAreaFields
    };
    return areaObj[type] ? areaObj[type] : [];
  }

  /**
   * @description: 查找指定column
   * @param {Array} areaFields: 区域表column集合
   * @param {String} uuid: 查找唯一uuid
   * @return: Column
   */
  findColumn(areaFields = [], findValue = "", propertyName = "") {
    return areaFields.find(item => item[propertyName] === findValue);
  }

  
}
