<!--
 * @Descripttion: 单选下拉框 基础&tree树 模式
 * @Author: 梁平贤
 * @Date: 2020-07-09 10:17:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-22 15:00:30
-->
<template>
  <div class='bm-select-column'>
    <el-input v-if="isAutoAssign" v-model="columnShowValue"  placeholder="请选择" @focus="openPopAutoAssign"></el-input>
    <el-popover
      v-else-if="popoverStyle"
      placement="bottom-start"
      trigger="manual"
      :value="showAssistViewDialog"
      popper-class="select-assist-view"
      v-click-outside="handleClickOutSide"
    >
      <div class="select-assist-wrap">
        <div class="assist-view-item" v-for="(item,index) in column.assistView" :key="index">
          <span class="dotted"></span>
          <span>{{item.name}}: {{ getAssistViewValue(item) }}</span>
        </div>
      </div>
      <div
        slot="reference"
        class="model-select focus-within"
        :class="{'has-right': hasRightBtn, 'is-focus': isFocus, 'is-disabled': isDisabled}"
      >
        <!--  :mode="normal：列表，tree：树形"  default: normal -->
        <!--  :show-assist="true 弹出assist辅助选项" default: false -->
        <!--  :disabled="Boolean 是否禁用" default: false -->
        <!--  :multiple="Boolean 是否多选" default: false -->
        <!--  :multiple-limit="Number 多选数量" default: 0 -->
        <!--  :allow-visible="Boolean select是否能展开" default: true -->
        <!--  :not-allow-visible="callback 不允许展开 点击后回调" default: false -->
        <!--  :filterable="Boolean 是否可搜索" default: false -->
        <!--  :lazy="Boolean 是否懒加载" default: false -->
        <!--  :load="function 懒加载请求数据fn" 配合lazy使用 -->
        <!--  :thum-tag="function "  -->
        <!--  :clearable="Boolean 是否开启清除" default: false -->
        <!--  :custom-option-add="Boolean 是否使用自定义添加" default: false  method:custom-option-add-method-->
        <!-- 单选模式 check-mode固定为：normal模式    siblings模式只允许勾选同子集选项--->
        <en-select
          ref="selectControl"
          :id="column.field"
          v-model="column.showValue"
          :placeholder="column.showPlaceholder"
          :check-mode="'normal'"
          :mode="column.selectMode"
          :data="options"
          :props="column.defaultProps"
          :disabled="column.disabled || !column.editable"
          :filterable="column.filterable"
          align="left"
          :multiple-limit="column.multipleLimit"
          :thum-tag="column.thumTag"
          @change="onChange"
          @visible-change="visibleChange"
          data-mode="data"
          :assist="column.assistView && column.assistView.length > 0"
          :allow-create="column.allowCreate"
          :multiple="isMultiSelect"
          :clearable="isMultiSelect"
          @clear="clearMultiSelect"
          :lazy="lazy"
          :isPeople="isPeople"
          :load="treeLoad"
          :style="[bindStyle, nodeStyle,inputStyle(column.field,column.areaObjType)]"
          :page-size="pageSize"
          :loading="loading"
          :allow-visible="canOpenSelect"
          :remote="column.remote"
          :remote-method="remoteMethod"
          :showTexy = "isShowText()"
          @not-allow-visible="onNotAllowVisible"
          :custom-option-add="!!column.areaObjType"
          @custom-option-add-method="onCustomOptionAddMethod"
        >
          <template #assist="{ data }">
            <span class="custom-assist">
              <span class="assist-block" v-for="(item, index) in column.assistSelect" :key="index">
                <span v-if="data[item.field]">{{ item.name }}: {{ data[item.field] }}</span>
              </span>
            </span>
          </template>
        </en-select>
        <RightAddition v-if="hasRightBtn"
                      :column="column"
                      :row-index="rowIndex"
                      :business-data="businessData"
                      @handleAssitViewClick="handleAssitViewClick"
        >
        </RightAddition>
      </div>
    </el-popover>

    <div
      v-else
      class="model-select focus-within"
      :class="{'has-right': hasRightBtn, 'is-focus': isFocus, 'is-disabled': isDisabled}"
    >
      <!--  :mode="normal：列表，tree：树形"  default: normal -->
      <!--  :show-assist="true 弹出assist辅助选项" default: false -->
      <!--  :disabled="Boolean 是否禁用" default: false -->
      <!--  :multiple="Boolean 是否多选" default: false -->
      <!--  :multiple-limit="Number 多选数量" default: 0 -->
      <!--  :allow-visible="Boolean select是否能展开" default: true -->
      <!--  :not-allow-visible="callback 不允许展开 点击后回调" default: false -->
      <!--  :filterable="Boolean 是否可搜索" default: false -->
      <!--  :lazy="Boolean 是否懒加载" default: false -->
      <!--  :load="function 懒加载请求数据fn" 配合lazy使用 -->
      <!--  :thum-tag="function "  -->
      <!--  :clearable="Boolean 是否开启清除" default: false -->
      <!--  :custom-option-add="Boolean 是否使用自定义添加" default: false  method:custom-option-add-method-->
      <!-- 单选模式 check-mode固定为：normal模式    siblings模式只允许勾选同子集选项--->
      <en-select
        ref="selectControl"
        v-model="column.showValue"
        :id="column.field"
        :placeholder="column.showPlaceholder"
        :check-mode="'normal'"
        :mode="column.selectMode"
        :data="options"
        :props="column.defaultProps"
        :disabled="column.disabled || !column.editable"
        :filterable="column.filterable"
        align="left"
        :multiple-limit="column.multipleLimit"
        :thum-tag="column.thumTag"
        @change="onChange"
        @visible-change="visibleChange"
        data-mode="data"
        :assist="column.assistView && column.assistView.length > 0"
        :allow-create="column.allowCreate"
        :multiple="isMultiSelect"
        :clearable="isMultiSelect"
        @clear="clearMultiSelect"
        :lazy="lazy"
        :load="treeLoad"
        :style="[bindStyle, nodeStyle,inputStyle(column.field,column.areaObjType)]"
        :page-size="pageSize"
        :loading="loading"
        :allow-visible="canOpenSelect"
        :remote="column.remote"
        :remote-method="remoteMethod"
        :fuzzy-search="false"
        :tabEdit="tabEdit"
        :isPeople="isPeople"
        @selectSure="selectSure"
        :showTexy = "isShowText()"
        @not-allow-visible="onNotAllowVisible"
        :custom-option-add="!!column.areaObjType"
        @custom-option-add-method="onCustomOptionAddMethod"
      >
        <template #assist="{ data }">
          <span class="custom-assist">
            <span class="assist-block" v-for="(item, index) in column.assistSelect" :key="index">
              <span v-if="data[item.field]">{{ item.name }}: {{ data[item.field] }}</span>
            </span>
          </span>
        </template>
        <template #tags="{showSelected,info}">
            <div :key="1">
              <el-popover
                placement="bottom"
                width="300"
                trigger="hover">
                  <div class="sculpture-list" slot="reference">
                    <div v-for="(item, index) in column.showValue.slice(0,3)" :key="index" class="sculpture-style"  >
                      <span v-if="index < 3">{{[...item.name][0] || ""}}</span>
                    </div>
                    <div v-if="column.showValue.length >= 3" class="sculpture-style-other">
                      +{{column.showValue.length - 3}}
                    </div>
                  </div>
                  <el-tag
                      v-for="item in showSelected"
                      :key="item.value"
                      :closable="!info.selectDisabled"
                      :size="info.collapseTagSize"
                      :hit="item.hitState"
                      type="info"
                      @close="deleteTag($event, item)"
                      disable-transitions
                      class="en-select-tag"
                    >
                      <template v-if="info.thumTag">
                        <img
                          :src="item.node.thum"
                          class="en-select__tags-thum en-select__tags-thum-image"
                          v-if="item.node && item.node.thum"
                        />
                        <span
                          class="en-select__tags-thum en-select__tags-thum-text"
                          :style="{
                            'background-color':
                              item.node && item.node.thumBackgroundColor
                                ? item.node.thumBackgroundColor
                                : ''
                          }"
                          v-else
                        >
                          {{
                            item.node && item.node.thumText
                              ? item.node.thumText
                              : item.currentLabel.substr(0, 1)
                          }}
                        </span>
                      </template>
                      <span
                        class="el-select__tags-text"
                        :style="{
                          color: item.node && item.node.color ? item.node.color : ''
                        }"
                      >{{ item.currentLabel }}</span
                      >
                  </el-tag>
              </el-popover>
            </div>
        </template>
      </en-select>
      <RightAddition v-if="hasRightBtn"
                    :column="column"
                    :row-index="rowIndex"
                    :business-data="businessData">
      </RightAddition>

    </div>
    <!-- 自动赋值 -->
    <pop-auto-assign ref="autoAssignMod" :column="column" @handleBaseChoose='selectSuccess' :loadDataFn="treeLoad"></pop-auto-assign>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
import { roundNumWithDeci } from "en-js";
import { selectService } from "@/api";
import { fmoney } from "@/tools/util.js";
import { dayJsTimeFormat } from "@/tools/date.js";
import { matchConfig } from "./matchRequestConfig";
import RightAddition from "../components/rightAddition";
import MxColumnData from "../MxColumnData.js";
import mixinRelation from "../components/relevancyAdd/MixinRelation";
import { AssignmentOptions, BusinessType, BusinessFieldType } from "../../../data/BusinessCommonHeader.js";
import { newTabAddBusinessData } from "../../../helper.js";
import MxRightAddition from "../components/rightAddition/MxRightAddition";
import PopAutoAssign from "../../popAutoAssign";

const isObject = (val) => Object.prototype.toString.call(val) === "[object Object]";
const isArray = (val) => Object.prototype.toString.call(val) === "[object Array]";
let debounceTimer = null;
export function debounce(callback, duration) {
  return function(...args) {
    const ctx = this;
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      console.log("定时器函数执行了");
      callback.apply(ctx, args);
    }, duration);
  };
}
export default {
  name: "BMSelectColumn",
  mixins: [MxColumnData, mixinRelation, MxRightAddition],
  props: {
    // 明细第几行
    rowIndex: {
      type: Number,
      default: 0
    },
    tabEdit: {
      type: Boolean,
      default: false
    },
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    RightAddition, PopAutoAssign
  },
  computed: {
    ...mapGetters(["userInfo"]),
    columnShowValue: {
     get() {
        return this.column.showValue && this.column.showValue[this.column.defaultProps.label];
     },
     set() {

     }
    },
    // 是否有右侧按钮组
    hasRightBtn() {
      return this.rightAdditionButtons?.length;
    },
    // 是否tree树
    isTree() {
      return Number(this.column.showStyle) !== 5;
    },
    // 能否展开select
    canOpenSelect() {
      return !(this.column.columnIsCascade && !this.prefixId);
    },
    prefixId() {
      return this.prefixColumn?.showValue?.id || false;
    },
    // 级联情况  查询上级级联column
    prefixColumn() {
      if (!this.column.columnIsCascade) return null;
      return this.getPrefixField();
    },
    isMultiSelect() {
      return this.column.isMultiSelect;
    },
    isPeople() {
      return ["001", "002"].includes(this.column.areaObjType);
    },
    isDisabled() {
      return this.column.disabled || !this.column.editable;
    },
    nodeStyle() {
      // 单选控件 指定数据范围 单个选项自定义的颜色
      const { dataArea, fieldType, columnValue } = this.column;
      if (fieldType === BusinessFieldType.select && dataArea.length && columnValue) {
        const opt = dataArea.find((itm) => itm.id === columnValue.id);
        if (opt) {
          return {
            color: opt.color
          };
        }
      }
      return "";
    },
    popoverStyle() {
      console.log(this.isDisabled, this.column, "this.isDisabledthis.isDisabled");
      return this.isDisabled && this.column.openAssistView && this.column.showValue;
    },
    isAutoAssign() {
      let { autoEvaluation } = this.column;
      autoEvaluation = typeof autoEvaluation === "string" ? JSON.parse(autoEvaluation) : autoEvaluation;
      return autoEvaluation.find((el) => {
        if (this.column.autoChooseArea !== "mainTopArea" && el.srcArea === this.column.autoChooseArea) {
          return true;
        }
        return false;
      });
    }
  },
  data() {
    return {
      loading: false,
      isFocus: false,
      lazy: false,
      pageSize: 20,
      options: [],
      openSelect: false,
      oldPrefixId: "", // 保存id 用于判断是否改变
      assistViewData: {},
      showAssistViewDialog: false
    };
  },
  methods: {
    deleteTag(event, tag) {
      this.column.showValue.splice(this.column.showValue.indexOf(tag.value), 1);
      this.onChange();
      event.stopPropagation();
    },
    // 银行卡信息设置初始值
    initDefaultValue() {
      if (this.column.field === "isDefault" && !this.column.columnValue) {
        this.column.updateValueWithOptions({ name: "是", id: "000" }, AssignmentOptions.manual);
      }
    },
    openPopAutoAssign() {
      this.$refs.autoAssignMod.visible = true;
    },
    // 多选清除
    clearMultiSelect() {
      this.column.showValue = [];
      this.column.updateValueWithOptions([], AssignmentOptions.manual);
    },
    onNotAllowVisible() {
      console.log("not allow visible", this.canOpenSelect, this.prefixId, this.prefixColumn);
      // 上级columnValue为空 提示请选择上级
      if (!this.prefixId) {
        this.$message(`请先选择${this.prefixColumn.name}`);
        // return;
      }
    },
    // 自动赋值选择回调
    selectSuccess(showValue, info) {
      this.column.showValue = showValue;
      this.column.autoAssignValue = info;// 自动赋值来自选择弹框的数据
      this.onChange();
    },
    selectSure() {
      this.$emit("trChange");
    },
    async onChange() {
      const {
        mappingRule, showValue, columnValue, field, produceType, multipleFlag
      } = this.column;
      if (_.isEqual(showValue, columnValue)) return;
      // 更新值
      console.log(showValue, AssignmentOptions.manual, "=]=]=]]=");
      this.column.updateValueWithOptions(showValue, AssignmentOptions.manual);
      // 关联添加(自动)
      if (mappingRule && mappingRule.length && produceType === 1) {
        if (!showValue) {
          this.fillRelationField({});
          return;
        }
        const { records } = await this.querySubDataList(multipleFlag ? 1 : 9999);
        console.log(records);
        // multipleFlag 1 单选 0多选
        if (multipleFlag === 1) {
          this.fillRelationMultiField([records[0]]);
        } else {
          this.fillRelationMultiField(records);
        }
      }

      this.resetBankCode();
      // bdQueryPersonDeptTree.ajax

      // 加班类型字段
      field === "overtimeType" && this.setOvertimeLongTime();
      // 请假类型字段
      field === "leaveType" && this.setleaveSurplusTime();
      // 模板上的关联属性 zjh.
      this.businessData.templateHandle?.associatedDataHandle?.handle(this.column);

      if (!this.isMultiSelect) {
        this.$emit("trChange");
      }
    },
    testFnc() {
      this.$emit("trChange");
    },
    // field == (bank开户行 || city开户城市)字段时  更改value 重置 field == bankCode 开户支行字段
    resetBankCode() {
      if (this.column.field !== "bank" && this.column.field !== "city") return;
      const column = this.column.detailSameAreaFields.find((v) => v.field === "bankCode"); // this.column.findColumn(this.getCurrentRegion(), "bankCode", "field");
      column.columnValue && column.updateValueWithOptions("", AssignmentOptions.automaticGetValue);
    },
    /**
     * select 显示隐藏
     * params {Boolean} bool true->显示
     */
    async visibleChange(bool) {
      this.isFocus = bool;
      // // 多选无级联
      // if (this.isMultiSelect) return;
      // // columnIsCascade 是否级联 isCascade
      // // columnIsCascade = true  prefixField对应字段查询
      // // 级联关系id cascadeGroupId = 320894826724790272 下级select查询options必传字段
      // // prefixField不为空 且 isCascade =1 表示当前 select无上级级联 有下级级联  级联uuid对应下级prefixField
      // // prefixField不为空 且 isCascade =0 表示当前select 有上级级联 级联prefixField对应上级uuid
      // if (bool && this.column.columnIsCascade) {
      //   // 否则 查询当前select options
      //   // cascadeGroupId 为查询options数据必传字段 prefixColumn.columnValue.id
      //   !(this.oldPrefixId && this.prefixId === this.oldPrefixId) && ((this.options = await this.initSelectOptions()), (this.oldPrefixId = this.prefixId));
      // }
      this.openSelect = bool;
      if (!(this.column.selectMode === "normal" && this.lazy) && bool && !this.options.length) {
        this.options = await this.initSelectOptions();
      }
    },
    // 获取上级数据
    getPrefixField() {
      // find 上级数据
      return this.column.findColumn(this.getCurrentRegion(), this.column.prefixField, "uuid");
    },
    // 获取当前区域
    getCurrentRegion() {
      const belongSectionType = this.column.belongSectionType;
      const areaFieldsObj = {
        1: this.column.mainAreaFields,
        2: this.column.detailSameAreaFields,
        3: this.column.subsetSameAreaFields
      };
      return areaFieldsObj[belongSectionType];
    },
    // tree树 加载子集数据
    async treeLoad(node, resolve, more) {
      // const {parent} = node;
      let data = [];
      if (this.isTree) {
        // const parentId = node.data?.id;
        if (this.openSelect && this.canInit()) {
          data = await this.initSelectOptions(node, more);
        }
      } else {
        data = await this.initSelectOptions({}, more);
      }
      resolve(data);
    },
    // 请求 select options
    async getSelectOptions(node = {}, more = false) {
      const { parent, data } = node;
      const parentId = parent ? data?.id : "";
      const { requestFnStr, requestData, responseData } = matchConfig(this);
      // responseData 有数据时 不再请求数据
      if (!requestFnStr || !requestData) {
        this.lazy = false;
        return responseData;
      }
      // 树形 请求children追加 id
      parentId && (requestData.id = parentId);
      // 平铺 基础数据 做加载更多
      if (this.lazy && this.pageSize) {
        requestData.pageSize = this.pageSize;
        requestData.pageNo = 1;
      }

      if (more) {
        let pageNo = requestData.pageNo;
        if (isArray(data) || (isObject(data) && parentId)) {
          const len = node.childNodes.length;
          pageNo = len / this.pageSize + 1;
        } else if (isObject(data) && !parentId) {
          pageNo = node.pageNo;
        } else if (!this.isTree) {
          const opts = this.$refs.selectControl.options || [];
          pageNo = opts.length / this.pageSize + 1;
        }
        requestData.pageSize = this.pageSize;
        requestData.pageNo = pageNo;
      }

      let res = await selectService[requestFnStr](requestData);
      if (this.column.field === "requestDept") {
        // 特殊处理 requestDept字段
        res = res.userDepatement || [];
      }
      let options = this.formatOptions(res || []);
      if (this.column.dataArea && this.column.dataArea.length) {
        let expectIds = this.column.dataArea.filter((item) => item.exceptFlag === 0);
        expectIds = expectIds.map((item) => item.id.replace("dept-", ""));
        options = options.filter((item) => !expectIds.includes(item.id));
      }

      return options;
    },
    // 保存新增自定义数据
    async saveCustomOption(customValue) {
      await selectService.saveCustomItem({
        field: this.column.field,
        uuid: this.column.uuid,
        data: JSON.stringify([{ dataName: customValue }]),
        type: this.column.type,
        templateId: this.businessData.mdInfo.templateId
      });
      this.options = await this.initSelectOptions();
      const selectedOption = this.options.find((opt) => opt.dataName === customValue);
      // 设置默认选中
      selectedOption && this.column.updateValueWithOptions(selectedOption, AssignmentOptions.manual);
    },
    /**
     * select 新增条目 唤起other add
     */
    onCustomOptionAddMethod(customValue) {
      // 非自定义 采用关联新增
      if (this.column.dataSource === 1) {
        // 先移除当前监听 再开启下次监听
        window.removeEventListener("storage", this.businessDetail_newUserDefinedOption);
        const { field, templateDataId } = this.column;
        // 打开新的页签新增options数据 保存后回调businessDetail_newUserDefinedOption
        newTabAddBusinessData({
          businessType: BusinessType.basic, // 业务建模
          templateId: templateDataId,
          field
        });
        window.addEventListener("storage", this.businessDetail_newUserDefinedOption);
        return;
      }
      // 多选无法输入框 新增
      customValue && this.saveCustomOption(customValue);
    },
    // 格式化数据为组件数据
    formatOptions(list = []) {
      // 如原数据不需保留  去掉Object.assign
      return list.map((item) => Object.assign(item, {
        disabled: this.itemIsDisabled(item),
        // 叶子 没有下级  依赖defaltProps定义 isLeaf
        leaf: this.itemIsLeaf(item)
        // 是否展开
        // isExpand: item.hasChildren === "000"
      }));
    },
    // 判断是展示文字还是带头像 组织机构 001、人员 002、  正常情况单选-纯文字、多选-文字+框 更多就是+数字
    // 特殊情况：在管理端配置了来源为基础数据的人员、机构这系统内容，单选、多选是头像+文字+框 更多+数字

    isShowText() {
      console.log(this.column.areaObjType, "this.column.areaObjTypethis.column.areaObjType");
      if (["001", "002"].includes(this.column.areaObjType)) {
        return false;
      }
        return true;
    },

    // 单一option是否disabled
    itemIsDisabled(item) {
      // 级联select  不需要后续判断
      if (this.column.columnIsCascade) return false;
      // 人员增加单独判断 type === "003" 为人员  || this.column.dataSource === 6
      if ((this.column.dataSource === 1 || !this.column.dataSource) && this.column.areaObjType === "002") {
        return item.type !== "003";
      }
      // disabled 解决tree树不允许勾选父项问题  hasChildren === "000"有子级
      // mode = normal disabled 需后续跟进需求处理 当前默认false
      return this.isTree ? !this.column.checkParent && item.hasChildren === "000" : false;
    },
    itemIsLeaf(item) {
      if (this.column.dataSource === 1 && this.column.areaObjType === "002") {
        return item.type === "003" || item.hasChildren === "001";
      }
      return item.hasChildren !== "000";
    },
    async initSelectOptions(node = {}, more = false) {
      // 第一级数据请求 重置options
      const { parent } = node;
      if (!parent && this.isTree && !more) {
        this.options = []; this.loading = true;
      }
      // !parent && this.isTree && !more && (this.options = [], this.loading = true);
      const options = await this.getSelectOptions(node, more);
      !parent && (this.loading = false);

      // 处理一下加班类型数据  数据返回 无 name
      const { field } = this.column;
      if (field === "overtimeType") { // 加班类型
        options.forEach((itm) => {
          itm.name = itm.text;
        });
      }

      return options;
    },
    // 判断是否需要init
    canInit() {
      // 暂时保留 后续补充
      // 如果当前column有父级联数据 且 未选中  return false
      // if (this.column.columnIsCascade) return false;
      return true;
    },
    // 业务建模新增了自定义选项
    businessDetail_newUserDefinedOption(event) {
      if (event && event.key === "businessDetail_newUserDefinedOption") {
        // 新增自定义选项
        let value = event.newValue;
        value = (value && JSON.parse(value)) || {};
        const {
          selectKey,
          id,
          name,
          field,
          defaultProps
        } = value;
        // 判断回调中field是否为当前控件field 不对应不执行任何操作
        // 可能存在多处监听
        if (selectKey?.field === this.column.field || field === this.column.field) {
          // 取值完成移除监听
          window.removeEventListener("storage", this.businessDetail_newUserDefinedOption);
          // 执行新增option操作  并默认选中
          const selectedOption = { id, name };
          // 判断是否已经存在  不存在则push
          if (defaultProps) {
            !this.options.find((opt) => opt[defaultProps?.value] === id) && this.options.concat(this.formatOptions([selectedOption]));
          }
          // 设置默认选中
          selectedOption && this.column.updateValueWithOptions(this.isMultiSelect ? [...this.column.columnValue, selectedOption] : selectedOption, AssignmentOptions.manual);
        }
        console.log(value);
      }
    },
    /**
     * @description 请假类型字段  设置请假时长
    */
    setleaveSurplusTime() {
      const leaveSurplusTime = this.column.findDetailSameAreaField("leaveSurplusTime");
      const leaveLongTime = this.column.findDetailSameAreaField("leaveLongTime");
      const { leaveBalance, id } = this.column.showValue;
      leaveSurplusTime.updateValueWithOptions(leaveBalance || 0, AssignmentOptions.change);
      // 改变请假类型，触发计算
      const _ltel = this.column.findDetailSameAreaField("leaveTime");
      const curtime = _ltel.showValue;
      if (curtime && curtime[0] && curtime[1]) {
        const _st = dayjs(curtime[0]).format(dayJsTimeFormat[10]);
        const _et = dayjs(curtime[1]).format(dayJsTimeFormat[10]);
        const param = {
          startTime: _st,
          endTime: _et,
          type: 1,
          userId: this.userInfo.userId,
          templateId: "",
          version: "",
          leaveType: id
        };
        this.calculateTime(param, this.column, leaveLongTime);
      }
    },
    /**
     * @description 加班类型字段  设置加班时长
    */
    setOvertimeLongTime() {
      const overtimeLongTime = this.column.findDetailSameAreaField("overtimeLongTime");
      const { leaveBalance, id } = this.column.showValue;
      overtimeLongTime.updateValueWithOptions(leaveBalance || 0, AssignmentOptions.change);
      // 触发加班时间计算
      const overtimeTime = this.column.findDetailSameAreaField("overtimeTime");
      const { showValue } = overtimeTime;
      if (showValue && showValue[0] && showValue[1]) {
        const _st = dayjs(showValue[0]).format(dayJsTimeFormat[10]);
        const _et = dayjs(showValue[1]).format(dayJsTimeFormat[10]);
        const param = {
          startTime: _st,
          endTime: _et,
          type: 2,
          userId: this.userInfo.userId,
          templateId: "",
          version: "",
          overtimeType: id
        };
        this.calculateTime(param, this.column, overtimeLongTime);
      }
    },
    /**
     * @description 计算考勤（请假，销假，时长） 该方法需要抽出去 考勤类型模板的时间字段也需要用
     * @param params 请求参数
     * @param srcCol 触发字段
     * @param tarColumn 目标设置字段
    */
    async calculateTime(params, srcColumn, tarColumn) {
      const rsp = await selectService.calculateTime(params);
      const { reserverDecimal, percentFlag, field } = tarColumn;
      const { hours, days } = rsp;
      const v = Number(hours || 0);
      if (percentFlag !== 0) { // 不为百分比
        // 计算请假时长 或者 加班时长
        // const lv = v.round(reserverDecimal.toString());
        const lv = roundNumWithDeci(v, reserverDecimal);
        tarColumn.updateValueWithOptions(lv, AssignmentOptions.change);
        // 计算请假天数 或者 加班天数
        const _key = field === "leaveLongTime" ? "leaveDays" : field === "overtimeLongTime" ? "overtimeDays" : "";
        if (_key) {
          const _column = srcColumn.findDetailSameAreaField(_key);
          if (!_column) {
            return;
          }
          const decimal = _column.reserverDecimal;
          let wd = Number(days || 0);
          if (_column.percentFlag !== 0) { // 不为百分比
            // wd = wd.round(decimal.toString());
              wd = roundNumWithDeci(wd, decimal);
            _column.updateValueWithOptions(wd, AssignmentOptions.change);
          }
        }
      }
    },
    /**
     * @description 查询单选辅助查看数据
    */
    async queryAssistViewData() {
      const {
        openAssistView, showValue, areaObjTypeId, columnValue
      } = this.column;
      if (!columnValue || (Array.isArray(columnValue) && !columnValue.length)) {
        return;
      }
      if (this.isDisabled && openAssistView && !Object.keys(this.assistViewData).length) {
        this.assistViewData = await selectService.queryBaseInfo({
          objectId: Array.isArray(showValue) ? showValue[0].id : showValue.id,
          objTypeId: areaObjTypeId
        });
      }
    },
    /**
     * @description: 辅助查看数据处理
     * @param item
     */
    getAssistViewValue(item) {
      const { field, fieldType } = item;
      const data = this.assistViewData[field] || "";
      let valueText = "";
      if (fieldType.toString() === "5") {
        valueText = data[0]?.name;
      } else if (fieldType.toString() === "4") {
        valueText = fmoney(Number(data || 0));
      } else {
        valueText = data;
      }
      return valueText;
    },
    // 远程搜索方法
    async remoteMethod(value) {
      const { requestFnStr, requestData } = matchConfig(this);
      if (value) {
        requestData.name = value;
      }
      const res = await selectService[requestFnStr](requestData);
      const options = this.formatOptions(res || []);
      this.options = options;
    },
    handleClickOutSide() {
      this.showAssistViewDialog = false;
    },
    handleAssitViewClick() {
      if (this.column.showValue.length || this.column.showValue) {
        this.showAssistViewDialog = !this.showAssistViewDialog;
        if (this.showAssistViewDialog) {
          this.queryAssistViewData();
        }
      }
    }
  },
  watch: {
    "column.showValue": {
      handler() {
        // 自动赋值字段 autoAssignment
        this.column.autoAssignment && this.column.setAutoAssignment();
      }
    }
  },
  created() {
    // 模板参数暂未明白 以下为临时自定义数据
    // 配置中允许 新增条目 则 filterable = true
    // allowCreate === true ==> filterable=true
    const defaultParams = {
      // todo基础数据判断有问题
      selectMode: Number(this.column.showStyle) !== 5 ? "tree" : "normal", // 下拉框类型  // 当前设置 自定义:normal  其他：tree
      filterable: true // 开启搜索
    };
    this.initDefaultValue();
    Object.assign(this.column, defaultParams);
    // 树形为异步加载 还有 非属性但是来源基础数据且平铺
    // this.lazy = this.isTree;
    this.lazy = this.isTree || (this.column.dataSource === 1 && Number(this.column.showStyle) === 5);
  },
  mounted() {
    this.$nextTick(() => {
        this.businessData.templateHandle?.associatedDataHandle?.handle(this.column);
    });
  }
};
</script>

<style scoped lang="scss">
.model-select {
  display: flex;
  align-items: center;
  position: relative;
  .en-select{
    & /deep/ .el-input{
      color: inherit;
    }
    &:hover /deep/ .el-input__inner{
      border-color: $field-border-color-hover;
    }
    &:hover /deep/ .el-input__inner:focus{
      border-color: $field-border-color-focus;
    }
    & /deep/ .el-input__inner:hover{
      border-color: $field-border-color-hover;
    }
    & /deep/ .el-input__inner:focus{
      border-color: $field-border-color-focus;
    }
    &:hover /deep/ .el-input__suffix>.el-input__icon{
      display: none !important;
    }
    & /deep/ .is-focus .el-input__suffix>.el-input__icon{
       display: none !important;
    }
  }
  & /deep/ .el-input.is-disabled{
    & /deep/ .el-input__inner{
      border-color: #E4E7ED;
    }
  }

 /** select has right btn group */
  &.has-right{
    border: 1px solid #e8ecf2;
    border-radius: 4px;
    line-height: 30px;
    &:hover{
      border-color: #a2cdf7;
    }
    &.is-focus{
      border-color: #409EFF;
    }
    &.is-disabled{
      border-color: #E4E7ED;
    }

    & /deep/ .el-input__inner{
      border:none;
      height: 30px;
      line-height: 30px;
      min-height: 30px;
    }
    & /deep/ .el-input__icon{
      height: 30px;
    }
    .right-addition{
      padding: 0 10px;
      position: relative;
      display: flex;
      align-items: center;
      &::after {
          content: " ";
          display: block;
          height: 20px;
          position: absolute;
          background-color: #E8ECF2;
          width: 1px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
      }
      & /deep/ .icon-placeholder{
        position: relative;
        // top: 4px;
        margin-top: 0;
      }
    }
    & /deep/ .el-input.is-disabled{
      & /deep/ .el-input__inner{
        border-color: #E4E7ED;
      }
    }
  }

  &.is-disabled{
    background: #f5f7fa;
    border-color: #f5f7fa !important;
    color: #636c78;
    border-radius: 5px;
    cursor: not-allowed;
    & /deep/ .el-input__inner:disabled{
      //查看 状态 统一样式
      background: #f5f7fa;
      border-color: #f5f7fa;
    }
    & /deep/ .el-icon-arrow-up{
      display: none;
    }
  }
}

/* 带右侧按钮组select error style */
.el-form-item{
  &.is-error{
    .has-right{
      border-color: #F56C6C;
    }
  }

}

.el-select {
  /*width: calc(100% - 36px);*/
  width: 100%;
}

.custom-assist {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  .assist-block {
    margin-right: 20px;
  }
}
.sculpture-list{
  margin-left:5px;
  width: 80px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  .sculpture-style-other{
    margin-left:-5px;
    width: 22px;
    height: 22px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #B4BED8;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .sculpture-style{
    margin-left:-5px;
    width: 22px;
    height: 22px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.name-style{
  width: 78px;
  height: 24px;
  background: #F5F8FC;
  border: 1px solid #DCE5EC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .name-sculpture{
    margin-left:4px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .name-text{
    margin-left:4px;
    width: 46px;
    height: 13px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1A1C1E;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.en-select__tags-thum {
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      color: #ffffff;
      overflow: hidden;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      margin-right: 4px;
      position: relative;
      top: -1px;
      background-color: #3e90fe;
      font-size: 12px;
    }
.en-select-tag{
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.select-assist-view{
  padding: 0;
  background: rgba(64, 67, 82, 0.6);
  .select-assist-wrap{
    padding: 10px;
    color: #ffffff;
    border-radius: 3px;
    width: 280px;
    // height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
    .assist-view-item{
      // overflow: hidden;
      // text-overflow: ellipsis;
      font-size: 12px;
      padding: 4px 0;
      >span{
        vertical-align: middle;
      }
      .dotted{
        display: inline-block;
        width: 0;
        height: 0;
        border: 2px solid #4B94D9;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  &.el-popper[x-placement^=bottom] .popper__arrow{
    border-bottom-color: transparent;
  }
  &.el-popover[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: rgba(64, 67, 82, 0.6);
  }
  .popper__arrow{
    left: 20px !important;
    top: -7px !important;
  }
}
</style>
