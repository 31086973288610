/*
 * @Descripttion: 映射规则 基础处理
 * @Author: 彭博
 * @Date: 2020-08-03 16:08:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-28 16:42:42
 */
export default function createMappingRule(target) {
  /**
   * 表格取值 或者其他  值是数组或者 字符串
   * @param {*} values
   * @returns string
   */
  target.prototype.valueToText = function toText(values, defaultConfig = this) {
    // 为空 直接返回
    if (values === "") return values;
    // 单选多选类型 显示字符串 value 转换判断
    if ((defaultConfig.fieldType === 5 || defaultConfig.fieldType === 15) && typeof values === "string") {
      values = JSON.parse(values);
    }
    if (Object.prototype.toString.call(values) === "[object Array]") {
      return values.map((item) => item.name).join("");
    }
    return values;
  };
  /**
   * 处理来源字段type !== 目标字段type 时数据的转换
   * @params {any} 来源字段对应的值
   * @params {Object} 单个字段映射规则
   * @returns 转换后给予目标字段的值
   */
  target.prototype.formatSourceValue = function formatValue(values, rule = {}, mappingData = {}) {
    // values 为空
    if (values === "" || values === undefined) return values;
    // 时间转换   起止日期格式 dataRange 1  转 dataRange 0
    if (rule.targetFieldType === 7) {
      const targetItem = this.sub.find((item) => item.field === rule.targetField); // 查找当前日期数据
      if (targetItem && targetItem.dateRange === 1) {
        return values?.split(",")[0];
      }
    }
    if (rule.sourceField === "id" && rule.targetFieldType === 5) {
      values = [{ id: mappingData.id, name: mappingData.name }];
      return values;
   }
    // 类型相同 不转换
    if (rule.sourceFieldType === rule.targetFieldType) {
      return values;
    }
    if (rule.targetFieldType === 1) {
      // 地址处理
      if (rule.sourceFieldType === 29) {
        const addrList = [];
        if (values.provinceName) {
          addrList.push(values.provinceName);
        }
        if (values.cityName) {
          addrList.push(values.cityName);
        }
        if (values.districtName) {
          addrList.push(values.districtName);
        }
        if (values.address) {
          addrList.push(values.address);
        }
        return addrList.join("");
        // 文本框兼容模式 不转换
      }
    }
    return this.valueToText(values); // 其他情况如果差异 待补充
  };
  /**
   * 字段映射
   * @param {Object} mappingData 映射数据源
   * @param {Array} mappingRule 映射规则数据
   * @returns 根据映射规则转换后给与目标使用数据 targetField
   */
  target.prototype.formatMappingRule = function format(mappingData = {}, mappingRule = this.mappingRule) {
    const params = { mainData: new Map(), lineData: [], subData: [] };
    mappingRule?.forEach((item) => {
      const targetColumn = this.findColumnByField(item.targetField);
      if (targetColumn?.belongSectionType === 1) {
        params.mainData[targetColumn] = params.mainData.set(targetColumn, this.formatSourceValue(mappingData[item.sourceField], item));
      } else if (targetColumn?.belongSectionType === 2) {
        params.lineData[item.targetField] = this.formatSourceValue(mappingData[item.sourceField], item, mappingData);
      } else {
        params.subData[item.targetField] = this.formatSourceValue(mappingData[item.sourceField], item);
      }
    });
    return params;
  };
}
